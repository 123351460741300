<template>
  <div class="page-root">
    <div class="top-main">
      <img class="image" alt="logo" src="../../assets/adhd/top-main.png" />
      <route-diagnose-start custom-button-class="start-button" />
    </div>

    <div class="check">
      <div class="text">
        ※このチェックリストは、ADHDの特性があるかどうかを確かめるものであり、診断結果を表すものではありません。ADHDの症状と似た症状を示す疾患は多く、ADHDとその他疾患を区別するには慎重な診断が必要となります。
      </div>
    </div>

    <div class="question">
      <div class="title">
        セルフチェックで何がわかるの？
      </div>

      <div class="title-logo">
        <div class="display-flex flex-align-center">
          <div class="logo">
            <img alt="logo" src="../../assets/adhd/top-explanation01.png" />
          </div>
          <div class="logo-text">
            ６つの設問に回答することで、ADHDの特性に当てはまるかをチェックできます。
          </div>
        </div>
      </div>

      <div class="text">
        結果として、ADHDの可能性がある方は、さらに詳しい「セルフ問診」を受けることができます。
      </div>
    </div>

    <div class="debut">
      <div class="title">
        ADHDの特性がある場合は？
      </div>

      <div class="title-description">
        チェック後に、ADHDについて相談することや学ぶことができます。
      </div>

      <div class="display-flex flex-align-center description">
        <div class="logo">
          <img alt="logo" src="../../assets/adhd/top-explanation02.png" />
        </div>
        <div class="logo-text">
          専門医在籍の医療施設・病院を検索可能
        </div>
      </div>

      <div class="display-flex flex-align-center description">
        <div class="logo">
          <img alt="logo" src="../../assets/adhd/top-explanation03.png" />
        </div>
        <div class="logo-text">
          受診するべきかなど、気軽にネットで医師へ相談が可能
        </div>
      </div>

      <div class="display-flex flex-align-center description">
        <div class="logo">
          <img alt="logo" src="../../assets/adhd/top-explanation04.png" />
        </div>
        <div class="logo-text">
          ADHDの対処法・体験談など様々なお役立ちコンテンツを掲載
        </div>
      </div>

      <div class="check-text">
        まずは２分で簡単チェック！
      </div>

      <div class="start-button">
        <route-diagnose-start />
      </div>
    </div>

    <div class="recommend">
      <div class="title">
        こんな悩みをお持ちの方におすすめ
      </div>

      <ul>
        <li>物事を最後までやりとげられない</li>
        <li>作業を順序だてて行うことができない</li>
        <li>約束や、用事をよく忘れる</li>
        <li>じっくり考える必要があることを避けてしまう</li>
        <li>
          長時間座っていなければならないときに、つい手足を動かしたりもぞもぞしてしまう
        </li>
        <li>何かせずにいられなくなってしまう</li>
      </ul>

      <div class="img">
        <img alt="logo" src="@/assets/adhd/top-visual.png" />
      </div>

      <div class="text">
        少しでも気になる方はまずチェック！
      </div>

      <div class="start-button">
        <route-diagnose-start />
      </div>
    </div>

    <div class="right-holder">
      <div class="text">
        The Adult ADHD Self-Report Scale-V1.1(ASRS-V1.1) Symptoms Checklist is copyrighted by Lenard Adler, Ronald Kessler, Thomas Spencer.<br>
        The scale was translated by Toshinobu Takeda, Ryukoku University.
      </div>
    </div>

    <ProgressModal :is-modal-active="isConfirmUserInfo" />
  </div>
</template>

<script>
import RouteDiagnoseStart from "@/components/adhd/RouteDiagnoseStart";
import ProgressModal from "@/components/adhd/ProgressModal";
export default {
  name: "AdhdHome",
  head: {
    title: {
      inner: "大人のADHDセルフチェック"
    }
  },
  data() {
    return {
      isConfirmUserInfo: true
    };
  },
  components: { RouteDiagnoseStart, ProgressModal },
  beforeCreate: function() {
    const url = new URL(
      "v2/api/self_checker/user_info",
      process.env.VUE_APP_API_BASE_URL
    ).toString();
    this.axios
      .get(url, {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        this.$store.commit("adhd/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
        this.isConfirmUserInfo = false;
      })
      .catch(() => {
        this.$router.push("/adhd/error");
      });
  },
  created() {
    this.$store.commit("adhd/resetAllState");
  }
};
</script>

<style scope>
@import "../../assets/style/adhd.css";
.top-main {
  background-color: #28c8be;
  position: relative;
}
.top-main .image {
  padding: 24px 16px;
}
.top-main .start-button {
  position: absolute;
  width: 320px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.check {
  background-color: #f8f8f8;
}
.check .text {
  padding: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
}
.question {
  margin-top: 40px;
}
.question .title {
  color: #28c8be;
  font-size: 20px;
  font-weight: 700;
}
.question .title-logo {
  margin: 16px;
}
.question .text {
  margin: 0px 16px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.debut {
  margin-top: 48px;
}
.debut .title {
  color: #28c8be;
  font-size: 20px;
  font-weight: 700;
}
.debut .title-description {
  margin: 24px 16px 0px 16px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}
.debut .description {
  margin: 16px;
  background-color: #f8f8f8;
  border-radius: 5px;
}
.debut .description .logo {
  width: 96px;
  margin: 16px 16px;
}
.debut .description .logo-text {
  flex: 1;
  margin: 16px 16px 16px 0px;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
}
.debut .check-text {
  margin-top: 24px;
  font-size: 15px;
  text-align: center;
}
.debut .start-button {
  margin: 8px 16px;
}

.recommend {
  margin-top: 48px;
}
.recommend .title {
  color: #28c8be;
  font-size: 20px;
  font-weight: 700;
}
.recommend ul {
  margin: 24px 16px 0px 40px;
  list-style-type: disc;
  text-align: left;
  font-size: 16px;
}

.recommend .img {
  margin-top: 24px;
}
.recommend .text {
  margin-top: 24px;
  font-size: 15px;
  text-align: center;
}
.recommend .start-button {
  margin: 8px 16px;
}

.right-holder {
  text-align:left;
  margin:30px 20px 10px;
}

.right-holder .text {
  font-size:14px;
}

@media (hover: hover) {
  .main-footer .text a:hover {
    text-decoration: none;
  }
}
@media (hover: none) {
  .main-footer .text a:active {
    text-decoration: none;
  }
}
</style>
