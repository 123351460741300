<template>
  <button class="diagnose-start-button" @click.stop="onClick">
    <div>
      <div class="left">ADHDセルフチェック開始</div>
      <div>
        <span class="material-symbols-outlined" style="transform:rotate(-90deg);color:#726109">
          expand_circle_down
        </span>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "DiagnoseStartButton",
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
.diagnose-start-button {
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  border-radius: 5px;
  background-color: #ffd915;
  box-shadow: 0px 4px 0px #d5b510;
}
.diagnose-start-button div {
  margin: auto;
  display: flex;
  align-items: center;
}

.diagnose-start-button div .left {
  padding: 4px 12px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #726109;
}

@media (hover: hover) {
  .diagnose-start-button:hover {
    background-color: #f3cf12;
  }
}
@media (hover: none) {
  .diagnose-start-button:active {
    background-color: #f3cf12;
  }
}
</style>
