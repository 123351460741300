<template>
  <b-modal v-model="isActive" :can-cancel="false" :width="108">
    <div class="modal-body">
      <div class="progress-circular">
        <v-progress-circular indeterminate color="white" />
      </div>
      <div class="text">
        読み込み中
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ProgressModal",
  props: {
    isModalActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isActive: true
    };
  },
  watch: {
    // v-modelに反映させるにはwatch経由で変更する以外上手くいかなかったので、watchして変更させています
    isModalActive: function(newValue) {
      this.isActive = newValue;
    }
  }
};
</script>

<style scoped>
.modal-body {
  background-color: #000000b2;
}

.modal-body .progress-circular {
  padding: 8px;
}
.modal-body .text {
  padding: 8px;
  font-weight: 700;
  color: #FFFFFF;
}
</style>