<template>
  <b-modal v-model="isActive" :width="450" scroll="keep" @close="onClose">
    <div class="card">
      <div class="card-content">
        <div class="content">
          <p class="login-text">
            AskDoctorsの無料会員登録<br />またはログインが必要です。
          </p>
          <a :href="loginUrl" @click="sendGa('click', gaType, 'login')">
            <CommonButton text="会員の方はログイン" styleType="active" />
          </a>
          <b-field>
            <b-switch v-model="isSendAskDoctorsEmail" size="is-small"
              >AskDoctorsお役立ちメルマガを受け取る</b-switch
            >
          </b-field>
          <p>
            <small
              ><a
                href="https://www.askdoctors.jp/open/terms/"
                target="_blank"
                class="with-underline"
                >利用規約</a
              >と<a
                href="https://corporate.m3.com/privacy/"
                target="_blank"
                class="with-underline"
                >個人情報の取り扱い</a
              >に<br />
              同意のうえ、会員登録/ログインをしてください</small
            >
          </p>
          <div>
            <div class="google-btn">
              <a
                @click="sendGa('click', gaType, 'google-register')"
                :href="googleLoginUrl"
              >
                <p class="google-btn-logo">
                  <img src="@/assets/hae/logo_google.svg" />
                </p>
                <p class="google-btn-txt">Googleアカウントで続ける</p>
              </a>
            </div>
            <div class="yahoo-btn">
              <a
                @click="sendGa('click', gaType, 'yahoo-register')"
                :href="yahooLoginUrl"
              >
                <p class="yahoo-btn-logo">
                  <img src="@/assets/hae/logo_yahoo.png" />
                </p>
                <p class="yahoo-btn-txt">Yahoo! JAPAN IDで続ける</p>
              </a>
            </div>
            <div class="line-btn">
              <a
                @click="sendGa('click', gaType, 'line-register')"
                :href="lineLoginUrl"
              >
                <p class="line-btn-logo">
                  <img src="@/assets/hae/logo_line.png" />
                </p>
                <p class="line-btn-txt">LINEで続ける</p>
              </a>
            </div>
            <div class="apple-btn">
              <a
                @click="sendGa('click', gaType, 'apple-register')"
                :href="appleLoginUrl"
              >
                <p class="apple-btn-logo">
                  <img src="@/assets/hae/logo_apple.png" />
                </p>
                <p class="apple-btn-txt">Appleでサインアップ</p>
              </a>
            </div>
            <p><small>※個人のSNSに利用状況は投稿されません</small></p>
            <p>または</p>
            <div>
              <p>
                <a
                  :href="freeRegisterUrl"
                  class="email-btn button button--tertiary button--md"
                  @click="sendGa('click', gaType, 'free-register')"
                  >メールアドレスで登録</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CommonButton from "@/components/hae/CommonButton.vue";
export default {
  name: "LoginModal",
  props: {
    gaType: {
      type: String,
      required: true
    },
    redirectUrl: {
      type: String,
      required: true
    },
    isLoginModalActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isSendAskDoctorsEmail: true,
      isActive: false
    };
  },
  components: { CommonButton },
  computed: {
    googleLoginUrl() {
      return this.buildOpenIdLoginUrl("google");
    },
    yahooLoginUrl() {
      return this.buildOpenIdLoginUrl("yahoojp");
    },
    lineLoginUrl() {
      return this.buildOpenIdLoginUrl("line");
    },
    appleLoginUrl() {
      return this.buildOpenIdLoginUrl("apple");
    },
    freeRegisterUrl() {
      const url = new URL(
        "user/pre-register/free",
        process.env.VUE_APP_API_BASE_URL
      );
      url.search = this.urlParams("");

      return url.toString();
    },
    loginUrl() {
      const params = new URLSearchParams();
      params.set("return_to", this.redirectUrl);

      const url = new URL("login", process.env.VUE_APP_API_BASE_URL);
      url.search = params.toString();

      return url.toString();
    }
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    buildOpenIdLoginUrl(provider) {
      const url = new URL(
        "user/free_registration_session/new",
        process.env.VUE_APP_API_BASE_URL
      );
      url.search = this.urlParams(provider);

      return url.toString();
    },
    urlParams(provider) {
      const params = new URLSearchParams();
      params.set("no_confirm", true);
      params.set("send_ask_doctors_email", this.isSendAskDoctorsEmail ? 1 : 0);
      params.set("return_to", this.redirectUrl);
      params.set("immediately_return", true);
      if (provider) {
        params.set("provider", provider);
      }

      return params.toString();
    }
  },
  watch: {
    // v-modelに反映させるにはwatch経由で変更する以外上手くいかなかったので、watchして変更させています
    isLoginModalActive: function(newValue) {
      this.isActive = newValue;
    }
  }
};
</script>

<style scoped>
.google-btn-logo {
  display: inline-block;
  border-radius: 8px 0 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 62px;
}

@media (min-width: 1032px) {
  .google-btn-logo {
    border-radius: 4px 0 0 4px;
  }
}
.line-btn-logo,
.yahoo-btn-logo,
.apple-btn-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 40px;
}

.line-btn a,
.yahoo-btn a,
.google-btn a,
.apple-btn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
}

a {
  color: #324646;
}

.with-underline {
  text-decoration: underline;
}

.google-btn {
  border: 1px solid #dce1e1;
  background: #fff;
  border-radius: 6px;
}

.line-btn,
.yahoo-btn,
.google-btn,
.apple-btn {
  margin: 0 auto 10px;
  height: 44px;
  width: 100% !important;
  position: relative;
}

.line-btn-txt,
.yahoo-btn-txt,
.apple-btn-txt {
  color: #fff;
}

.line-btn-txt,
.yahoo-btn-txt,
.google-btn-txt,
.apple-btn-txt {
  width: 100%;
  text-align: center;
  line-height: 18px;
  font-size: 14px;
  font-size: 0.875rem;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.yahoo-btn-logo {
  width: 62px;
  height: 40px;
  background: #fff;
  border-radius: 8px 0 0 8px;
  height: 40px;
  border-radius: 4px 0 0 4px;
}

.login-text {
  color: #ff8900;
  font-size: 20px;
  font-weight: bold;
}

.yahoo-btn {
  border: 2px solid #ff0033;
  background: #ff0033;
  border-radius: 6px;
}

.google-btn-logo img {
  height: 44px;
  border-radius: 8px;
}

.yahoo-btn-logo img {
  width: 32px;
}

.line-btn {
  border: none;
  background: #00c300;
  border-radius: 8px;
}
.line-btn-logo {
  width: 64px;
  border-right: 1px solid #00b300;
}
.line-btn-logo img {
  width: 40px;
}

.line-btn-logo img,
.yahoo-btn-logo img,
.google-btn-logo img,
.apple-btn-logo img {
  display: block;
  flex-shrink: 0;
}

.apple-btn {
  background: #000;
  border-radius: 6px;
}

.apple-btn-logo {
  width: 64px;
  border-radius: 8px 0 0 8px;
}

.apple-btn-logo img {
  height: 44px;
}

.apple-btn-txt,
.yahoo-btn-txt,
.line-btn-txt {
  font-weight: 700;
}

.email-btn {
  padding: 8px;
  width: 100% !important;
  font-size: 0.875rem;
}
</style>
