<template>
  <div>
    <div :class="customButtonClass" v-if="isLogined">
      <diagnose-start-button @click="onStart()" />
    </div>
    <div v-else>
      <div :class="customButtonClass">
        <diagnose-start-button
          @click="
            sendGa('click', 'adhd', 'start-screening-without-login');
            onLogin();
          "
        />
      </div>
      <login-modal
        gaType="adhd"
        :redirect-url="redirectUrl"
        :is-login-modal-active="isLoginModalActive"
        @close="onClose"
      />
    </div>
  </div>
</template>

<script>
import rootStore from "@/store";
import DiagnoseStartButton from "./DiagnoseStartButton";
import LoginModal from "@/components/login/LoginModal";
const state = rootStore.state;
export default {
  name: "RouteDiagnoseStart",
  data() {
    return {
      isLoginModalActive: false
    };
  },
  props: {
    customButtonClass: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: { DiagnoseStartButton, LoginModal },
  computed: {
    isLogined() {
      return (
        state.adhd.userStatus === "charged" || state.adhd.userStatus === "free"
      );
    },
    redirectUrl() {
      return new URL(
        "adhd/first_screening",
        process.env.VUE_APP_BASE_URL
      ).toString();
    }
  },
  methods: {
    async onStart() {
      await this.$router.push("/adhd/first_screening");
    },
    onLogin() {
      this.isLoginModalActive = true;
    },
    onClose() {
      this.isLoginModalActive = false;
    }
  }
};
</script>
